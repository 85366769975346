import React, { useEffect } from 'react';

export default(props) => {
  let email = props.match.params.email;
  useEffect(() => {
    const lm = LinkMink('pub_live_Y6eYkDxJqKysYlynYqBT');
    const options = {
      type: 'Starter Plan',
      status: 'success',
      match_with: 'subscription', // allowed values are subscription, customer, or charge
      livemode: true
    }
    lm.conversion(email, options).then();
    console.log(lm);
  }, [email])
  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-24 w-auto" src="https://dripshipper.s3-us-west-2.amazonaws.com/logos/Dripshipper_290118.png" alt="Workflow" />
            <h2 className="mt-6 text-3xl font-extrabold text-green-600">
              Congrats on signing up! We are redirecting you to your account.
            </h2>
            <p className="mt-2 text-sm text-gray-600 max-w">
              Or  <a href="/" className="font-medium text-brown-600 hover:text-brown-500"> click here to go to your account</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}