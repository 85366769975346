import React, { useState } from 'react';
import axios from 'axios';
import Alert from './templates/Alert';

export default(props) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    errors: []
  });
  const [clicked, setClicked] = useState(false);

  const toggleAlert = () => {
    setState({
      ...state,
      errors: []
    });
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (event) => {
    setClicked(true);
    event.preventDefault()
    const { email, password, password_confirmation } = state;
    let user = {
      email: email,
      password: password,
      password_confirmation: password_confirmation
    };
    axios.post('/api/v1/users', { user }, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'created') {
          let newUser = response.data.user;
          $FPROM.trackSignup({
            uid: newUser.stripe_id},
            function(){console.log('Callback received!')}
          );
          analytics.identify(newUser.id.toString(), {
            email: newUser.email,
          });
          analytics.track('Signed Up', {
            email: newUser.email,
          });
          props.handleLogin(response.data);
          redirect();
        } else {
          setState({ ...state,
            errors: response.data.errors
          });
          setClicked(false);
        }
      })
      .catch(error => console.log('api errors:', error));
  };
  const redirect = () => {
    props.history.push('/')
  }
  if (props.loggedInStatus) window.location = "/";
  
  let { errors } = state;
  console.log(errors, errors.length);
  return (
    <div className="min-h-screen bg-white flex">
      <Alert messages={errors} status="error" active={errors.length > 0} onClose={toggleAlert} />
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-24 w-auto" src="https://dripshipper.s3-us-west-2.amazonaws.com/logos/Dripshipper_290118.png" alt="Workflow" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Start your 14-day free trial
            </h2>
            <p className="mt-2 text-sm text-gray-600 max-w">
              Or  <a href="/login" className="font-medium text-brown-600 hover:text-brown-500"> log in to your account</a>
            </p>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input id="email" name="email" type="email" value={state.email} onChange={handleChange} autoComplete="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brown-500 focus:border-brown-500 sm:text-sm" />
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="mt-1">
                    <input id="password" name="password" type="password" value={state.password} onChange={handleChange} autoComplete="current-password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brown-500 focus:border-brown-500 sm:text-sm" />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    <a href="#" className="font-medium text-brown-600 hover:text-brown-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>
                <div>
                  <button type="submit" disabled={clicked} className={(clicked ? "cursor-not-allowed disabled:opacity-50 " : "") + "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brown-600 hover:bg-brown-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown-500"}>
                    Sign up
              </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img className="absolute inset-0 h-full w-full object-cover" src="https://dropshipdino.s3-us-west-1.amazonaws.com/App+Images/falling-box.png"  />
      </div>
    </div>
  )
}