import React, { useState } from 'react';
import axios from 'axios';
import Alert from './templates/Alert';

export default(props) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    errors: []
  });
  const [clicked, setClicked] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (event) => {
    setClicked(true);
    event.preventDefault()
    const { email, password } = state
    let user = {
      email: email.toLowerCase(),
      password: password
    };

    axios.post('/api/v1/users/login', { user }, { withCredentials: true })
      .then(response => {
        if (response.data.logged_in) {
          props.handleLogin(response.data);
          redirect();
        } else {
          setState({ ...state,
            errors: response.data.errors
          });
          setClicked(false);
        }
      })
      .catch(error => console.log('api errors:', error));
  }

  const redirect = () => {
    props.history.push('/')
  }

  const toggleAlert = () => {
    setState({
      ...state,
      errors: []
    });
  }

  if (props.loggedInStatus) window.location = "/";
  
  let { errors } = state;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Alert messages={errors} status={"error"} active={errors.length > 0} onClose={toggleAlert} />
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-24 w-auto" src="https://dripshipper.s3-us-west-2.amazonaws.com/logos/Dripshipper_290118.png" alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or <a href="/signup" className="font-medium text-brown-600 hover:text-brown-500">start your 14-day free trial</a>
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit} >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input id="email-address" name="email" type="email" value={state.email} onChange={handleChange} autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-brown-500 focus:border-brown-500 focus:z-10 sm:text-sm" placeholder="Email address" />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input id="password" name="password" type="password" value={state.password} onChange={handleChange} autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-brown-500 focus:border-brown-500 focus:z-10 sm:text-sm" placeholder="Password" />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a href="/forgot_password" className="font-medium text-brown-600 hover:text-brown-500">
                Forgot your password?
              </a>
            </div>
          </div>
          <div>
            <button type="submit" disabled={clicked} className={(clicked ? "cursor-not-allowed disabled:opacity-50 " : "") + "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brown-600 hover:bg-brown-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown-500"}>
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {/* Heroicon name: lock-closed */}
                <svg className="h-5 w-5 text-brown-500 group-hover:text-brown-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}