import React, { useState, useEffect } from 'react';
import Alert from './templates/Alert';
import axios from 'axios';

export default({ user }) => {
  const [status, setStatus] = useState('');
  const [clicked, setClicked] = useState(false);
  const [message, setMessage] = useState();
  const [domain, setDomain] = useState();

  const shopifyInstall = async (event) => {
    setStatus('');
    event.preventDefault();
    setClicked(true);
    try {
      let result = await axios.post('/api/v1/shopify/permission_url', { shop: domain, user_id: user.id }, { withCredentials: true });
      let resultStatus = result.data.status;
      if (resultStatus == "ok") {
        window.location = result.data.login_url;
      } else {
        setClicked(false);
        setStatus("error");
        setMessage(result.data.message);
      }
    } catch (error) {
      setClicked(false);
      setStatus("error");
      setMessage("Something went wrong, please try again. If the problem persists, please contact support.");
    }

  }

  const toggleAlert = () => {
    setStatus('');
  }

  const handleChange = (event) => {
    setDomain(event.target.value);
  }

  useEffect(() => {
    setDomain(user.myshopify_domain);
  }, [user.myshopify_domain]);

  return (
    <div className="mt-8">
      {status ? <Alert messages={[message]} status={status} active={status.length > 0} onClose={toggleAlert} /> : null}
      <div className="hidden sm:block">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Connect your Shopify store
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  Enter your myshopify domain name and click on the "Connect" button to install the Dripshipper App on your Shopify store. This app is necessary to create your products, add the products to your store, and for us to receive and fulfill all your orders.
                </p>
              </div>
              <form className="mt-5 sm:flex sm:items-baseline">
                <div className="max-w-xs w-full">
                  <input type="text" onChange={handleChange} value={domain} className="border focus:ring-brown-500 focus:border-brown-500 block w-full border-gray-300 rounded-md leading-9" placeholder="all-my-goods.myshopify.com" data-hj-allow />
                </div>
                  <button onClick={shopifyInstall} disabled={clicked} className={(clicked ? "cursor-not-allowed disabled:opacity-50 " : "") + "mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-brown-600 hover:bg-brown-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"}>
                    Connect
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}