import React, { useState } from 'react';
import axios from 'axios';

export default({ userID }) => {

  const getCheckoutSessionID = async (priceID) => {
    let result = await axios.get('/api/v1/stripe/get_checkout_session?price_id=' + priceID + '&user_id=' + userID);
    let sessionID = result.data.sessionID;
    sendToCheckoutPage(sessionID);
  }

  const sendToCheckoutPage = (sessionID) => {
    var stripe = Stripe(process.env.DRIPSHIPPER_STRIPE_PK);
    // Call Stripe.js method to redirect to the new Checkout page
    stripe.redirectToCheckout({
      sessionId: sessionID,
    })
      .then(handleResult);
  }

  const ELITE_PLAN_PRICE_ID = process.env.ELITE_PLAN_PRICE_ID
  const PRO_PLAN_PRICE_ID = process.env.PRO_PLAN_PRICE_ID;
  const STARTER_PLAN_PRICE_ID = process.env.STARTER_PLAN_PRICE_ID;

  return (
    <>
      {/* This example requires Tailwind CSS v2.0+ */}
      <div className="bg-brown-700">
        <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
          <div className="text-center">
            <p className="mt- text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
              Pricing Plans
            </p>
            <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-200 sm:mt-5 sm:text-2xl">
              Launch a coffee business online quickly & easily with Dripshipper. Get extra training and support with more advanced memberships.
            </p>
          </div>
        </div>
        <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="absolute inset-0 h-5/6 bg-brown-700 lg:h-2/3" />
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative lg:grid lg:grid-cols-7">
                <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                  <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-white px-6 py-10">
                        <div>
                          <h3 className="text-center text-2xl font-medium text-brown-700" id="tier-hobby">
                            Starter
                          </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl tracking-tight text-brown-700">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                $
                              </span>
                              <span className="font-extrabold">
                                30
                              </span>
                            </span>
                            <span className="text-xl font-medium text-gray-500">
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              {/* Heroicon name: outline/check */}
                              <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              Unlimited Orders
                            </p>
                          </li>
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              {/* Heroicon name: outline/check */}
                              <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              Unlimited products
                            </p>
                          </li>
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              {/* Heroicon name: outline/check */}
                              <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              Automatic order fulfillment
                            </p>
                          </li>
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <a onClick={() => getCheckoutSessionID(STARTER_PLAN_PRICE_ID)} className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-yellow-600 hover:bg-gray-50 cursor-pointer" aria-describedby="tier-hobby">
                              Start 14-day free trial
                      </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                  <div className="relative z-10 rounded-lg shadow-xl">
                    <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-yellow-600" aria-hidden="true" />
                    <div className="absolute inset-x-0 top-0 transform translate-y-px">
                      <div className="flex justify-center transform -translate-y-1/2">
                        <span className="inline-flex rounded-full bg-yellow-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                          Most successful use this plan
                  </span>
                      </div>
                    </div>
                    <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                      <div>
                        <h3 className="text-center text-3xl font-semibold text-brown-700 sm:-mx-6" id="tier-growth">
                          Professional
                  </h3>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl tracking-tight text-brown-700 sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                      </span>
                            <span className="font-extrabold">
                              99
                      </span>
                          </span>
                          <span className="text-2xl font-medium text-gray-500">
                            /month
                    </span>
                        </div>
                      </div>
                    </div>
                    <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                      <ul className="space-y-4">
                        <li className="flex items-start">
                          <div className="flex-shrink-0">

                          </div>
                          <p className="ml-3 text-base font-medium text-gray-500">
                            All starter features plus:
                          </p>
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            {/* Heroicon name: outline/check */}
                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base font-medium text-gray-500">
                            Monthly training
                          </p>
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            {/* Heroicon name: outline/check */}
                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base font-medium text-gray-500">
                            Priority support
                          </p>
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            {/* Heroicon name: outline/check */}
                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base font-medium text-gray-500">
                            Dedicated Success Manager
                          </p>
                        </li>
                      </ul>
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          <a onClick={() => getCheckoutSessionID(PRO_PLAN_PRICE_ID)} className="block w-full text-center rounded-lg border border-transparent bg-yellow-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-yellow-600" aria-describedby="tier-growth">
                            Start 14-day free trial
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                  <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-white px-6 py-10">
                        <div>
                          <h3 className="text-center text-2xl font-medium text-brown-700" id="tier-Elite">
                            Elite
                    </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl tracking-tight text-brown-700">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                $
                        </span>
                              <span className="font-extrabold">
                                197
                        </span>
                            </span>
                            <span className="text-xl font-medium text-gray-500">
                              /month
                      </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              {/* Heroicon name: outline/check */}
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              All features in Pro plan plus:
                            </p>
                          </li>
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              {/* Heroicon name: outline/check */}
                              <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              Monthly 1-on-1 coaching
                            </p>
                          </li>
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              {/* Heroicon name: outline/check */}
                              <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              In-house design team
                            </p>
                          </li>
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <a onClick={() => getCheckoutSessionID(ELITE_PLAN_PRICE_ID)} className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-yellow-600 hover:bg-gray-50" aria-describedby="tier-Elite">
                              Start 14-day free trial
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          {/* This example requires Tailwind CSS v2.0+ */}
          <div className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-12 px-4 divide-y-2 divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Frequently asked questions
              </h2>
              <div className="mt-6">
                <dl className="space-y-8 divide-y divide-gray-200">
                  <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                    <dt className="text-base font-medium text-gray-900 md:col-span-5">
                      What is the quality of your coffee?
                    </dt>
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      <p className="text-base text-gray-500">
                        The coffee is supplied by two of our roasting partners. The coffee is the freshest coffee available on the internet. Whole bean coffee is roasted the day it ships, and ground coffee is roasted the day after it ships (to allow for natural outgassing). We also use specialty grade beans for optimum flavor.
                      </p>
                    </dd>
                  </div>
                  <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                    <dt className="text-base font-medium text-gray-900 md:col-span-5">
                      How many coffee products are available?
                    </dt>
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      <p className="text-base text-gray-500">
                        We have over 40 different coffees you can add to your store, with your branding on it. We have single origin coffees, blends, flavored coffees, and sample packs.
                      </p>
                    </dd>
                  </div>
                  <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                    <dt className="text-base font-medium text-gray-900 md:col-span-5">
                      How can I get samples?
                    </dt>
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      <p className="text-base text-gray-500">
                        You can use our demo store <a className="font-medium text-brown-600 hover:text-brown-500" href="https://dogstreetroastingco.com" target="_blank">https://dogstreetroastingco.com</a> to obtain samples.
                      </p>
                    </dd>
                  </div>
                  <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                    <dt className="text-base font-medium text-gray-900 md:col-span-5">
                      Will my customers know I am dropshipping?
                    </dt>
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      <p className="text-base text-gray-500">
                        No, each of your customers will only see your brand & logo with no indication that it came from a dropshipping supplier. It looks like it came directly from you!
                      </p>
                    </dd>
                  </div>
                  <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                    <dt className="text-base font-medium text-gray-900 md:col-span-5">
                      How does dropshipping work?
                    </dt>
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      <p className="text-base text-gray-500">
                        <br />1. You build your packaging for the products you wish to sell.
                        <br />2. You add those products to your store.
                        <br />3. When a customer buys the product from you, you collect their payment.
                        <br />4. Once you have collected the payment (the order becomes marked as “paid”) from the customer, our software automatically identifies this order and will collect payment from your card on file to pay for the product and begin the process of fulfilling the order.
                        <br />5. It is your job to do NOTHING else. Do not mark the order as fulfilled, do not print shipping labels, just RELAX AND ENJOY as we take care of everything.
                        <br />6. When we are finished making the product for the order, we will ship the order, add tracking information to the order, and mark the order as “Fulfilled”.
                        <br />7. Shopify handles the payment for every sale made by your store.
                      </p>
                      <img src="https://uploads-ssl.webflow.com/601c754a550807278875c6a0/6022fb8bc46ca7773bbca20d_908826_DripShipperInfographics_120220.png" />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  )
}