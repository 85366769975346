import React from 'react';
import Banner from './templates/Banner';

const formatDate = (localDateStart) => {
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  var nd = new Date(localDateStart);
  var formattedDate = months[nd.getMonth()] + " " + nd.getDate() + " at "

  var hh = nd.getHours();
  var dd = "AM";
  var m = nd.getMinutes();
  var h = hh;
  if (h >= 12) {
    h = hh - 12;
    dd = "PM";
  }
  if (h == 0) {
    h = 12;
  }
  m = m < 10 ? "0" + m : m;

  var formattedTime = h + ":" + m;
  return formattedDate + formattedTime + " " + dd + " UTC−08:00";
}

export default({ user }) => {

  let shopifyDomain = user.myshopify_domain;

  const within30days = (date) => {
    let today = new Date();
    let orderDate = new Date(date);
    let thirtyDaysAgo = new Date().setDate(today.getDate() - 30);
    return orderDate > thirtyDaysAgo;
  }

  let salesTotal = 0;
  let shopifyOrders = user.shopify_orders;
  let lastTenOrders = [];
  if (shopifyOrders.length > 0) {
    let filteredOrders = shopifyOrders.filter(function (o) {
      return within30days(o.created_at);
    });
    filteredOrders.map((order) => {
      order.line_items.map((li) => {
        salesTotal += parseFloat(li.price);
      });
    });
    lastTenOrders = shopifyOrders.slice(1).slice(-10);
  }


  return (
    <div className={user.shopify_connected ? "mt-8" : ""}>
      {user.shopify_connected ? null : <Banner message="Connect your Shopify store to see latest sales data." />}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {/* Heroicon name: scale */}
                  <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                  </svg>
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      Sales (last 30 days)
                      </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">
                        {salesTotal.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <a href={shopifyDomain + "admin/orders?selectedView=all"} className="font-medium text-brown-700 hover:text-brown-900">
                  View all
                  </a>
              </div>
            </div>
          </div>
          {/* More items... */}
        </div>
      </div>
      <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        Recent orders (shipping charges not included)
        </h2>
      {/* Activity list (smallest breakopoint only) */}
      <div className="shadow sm:hidden">
        <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {lastTenOrders.map(o => <MobileOrderLine myshopify_domain={shopifyDomain} order={o} key={o.id} />)}
          {/* More items... */}
        </ul>
        <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200" aria-label="Pagination">
          <div className="flex-1 flex justify-between">
            <a href="#" className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Previous
              </a>
            <a href="#" className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Next
              </a>
          </div>
        </nav>
      </div>
      {/* Activity table (small breakopoint and up) */}
      <div className="hidden sm:block">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Customer
                      </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sales Amount
                      </th>
                    <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                      Status
                      </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                      </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {lastTenOrders.map(o => <OrderLine myshopify_domain={shopifyDomain} order={o} key={o.id} />)}
                  {/* More items... */}
                </tbody>
              </table>
              {/* Pagination */}
              <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                <div className="flex-1 flex justify-between sm:justify-end">
                  <a href={shopifyDomain + "admin/orders?selectedView=all"} className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                    View all
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MobileOrderLine = ({ order, myshopify_domain }) => {
  let shippingAddress = order.shipping_addresses[0];
  let lineItems = order.line_items;
  let orderSalesSum = lineItems.map(li => parseFloat(li.price)).reduce((a, b) => a + b, 0);

  return (
    <li>
      <a href={"https://" + myshopify_domain + "/admin/orders/" + order.shopify_order_id} target="_blank" className="block px-4 py-4 bg-white hover:bg-gray-50">
        <span className="flex items-center space-x-4">
          <span className="flex-1 flex space-x-2 truncate">
            {/* Heroicon name: cash */}
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
            </svg>
            <span className="flex flex-col text-gray-500 text-sm truncate">
              <span className="truncate">Payment to {shippingAddress ? shippingAddress.name : "Shipping address missing"}</span>
              <span><span className="text-gray-900 font-medium">{orderSalesSum.toLocaleString("en-US", { style: "currency", currency: "USD" })}</span> USD</span>
              <span>{formatDate(order.created_at)}</span>
            </span>
          </span>
          {/* Heroicon name: chevron-right */}
          <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </span>
      </a>
    </li>
  )
}

const OrderLine = ({ order, myshopify_domain }) => {
  let shippingAddress = order.shipping_addresses[0];
  let lineItems = order.line_items;
  let orderSalesSum = lineItems.map(li => parseFloat(li.price)).reduce((a, b) => a + b, 0);

  return (
    <tr className="bg-white">
      <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <div className="flex">
          <a href={"https://" + myshopify_domain + "/admin/orders/" + order.shopify_order_id} target="_blank" className="group inline-flex space-x-2 truncate text-sm">
            {/* Heroicon name: cash */}
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
            </svg>
            <p className="text-gray-500 truncate group-hover:text-gray-900">
              {shippingAddress ? shippingAddress.name + " in " + shippingAddress.city + ", " + shippingAddress.province_code + ", " + shippingAddress.country_code : "Shipping address missing"}
            </p>
          </a>
        </div>
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
        <span className="text-gray-900 font-medium">{orderSalesSum.toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>
        </td>
      <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
          success
        </span>
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
        {formatDate(order.created_at)}
      </td>
    </tr>
  )
}