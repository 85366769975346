import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react'
import MenuItem from './MenuItem';
import MainDashboard from './MainDashboard';
import GetStarted from './GetStarted';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ShopifyConnect from './ShopifyConnect';
import PricingPage from './PricingPage';
import Settings from './Settings';

export default(props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [page, setPage] = useState(1);
  const [authCode, setAuthCode] = useState();
  const [user, setUser] = useState({ id: 0, has_subscription: true, shopify_orders: [] });
  const [loading, setLoading] = useState(true);
  let store_connected = props.storeConnected;

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  }

  const changePage = (value) => {
    setPage(value);
  }

  const pages = [
    <MainDashboard user={user} />,
    <GetStarted authCode={authCode} user={user} changePage={changePage} loading={loading}  />,
    <GetStarted />,
    <GetStarted />,
    <GetStarted />,
    <Settings user={user} />,
    <GetStarted />,
    <GetStarted />,
    <ShopifyConnect user={user} changePage={changePage} />,
  ]

  if (!props.loggedInStatus && props.loginChecked) window.location = "/login";
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let retrievedAuthCode = query.get("auth_code");
  useEffect(() => {
    setAuthCode(retrievedAuthCode);
    if (retrievedAuthCode) setPage(7);
  }, [retrievedAuthCode]);

  useEffect(() => {
    const fetchData = async () => {
      let result = await axios.get('/api/v1/users/' + props.userID, {
        headers: {
          'apiKey': process.env.DRIPSHIPPER_APP_SECRET,
        }
      });
      setUser(result.data.user);
      setLoading(false);
    }
    if (props.userID) fetchData();
  }, [props.userID]);

  if (props.loginChecked) {
    return props.loggedInStatus ? 
      !props.has_subscription ? <PricingPage userID={props.userID} /> : (
      <>
        <div className="h-screen flex overflow-hidden bg-gray-100">
          {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
          <div className="lg:hidden">
            <div className="fixed inset-0 flex z-40">
              {
                /*
                Off-canvas menu overlay, show/hide based on off-canvas menu state.
  
                Entering: "transition-opacity ease-linear duration-300"`
                  From: "opacity-0"
                  To: "opacity-100"
                Leaving: "transition-opacity ease-linear duration-300"
                  From: "opacity-100"
                  To: "opacity-0"
                */
              }
              <Transition
                show={isOpen}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0">
                  <div className="absolute inset-0 bg-gray-600 opacity-75" aria-hidden="true" />
                </div>
              </Transition>
              {
                /*
                Off-canvas menu, show/hide based on off-canvas menu state.
  
                Entering: "transition ease-in-out duration-300 transform"
                  From: "-translate-x-full"
                  To: "translate-x-0"
                Leaving: "transition ease-in-out duration-300 transform"
                  From: "translate-x-0"
                  To: "-translate-x-full"
                */
              }
              <Transition
                show={isOpen}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-brown-700">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button onClick={toggleSidebar} className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Close sidebar</span>
                      {/* Heroicon name: x */}
                      <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img className="h-8 w-auto" src="https://dripshipper.s3-us-west-2.amazonaws.com/logos/white_with_transparent.png" alt="Easywire logo" />
                  </div>
                  <nav className="mt-5 flex-shrink-0 h-full divide-y divide-brown-600 overflow-y-auto" aria-label="Sidebar">
                    <div className="px-2 space-y-1">
                      {/* Current: "bg-brown-600 text-white", Default: "text-brown-100 hover:text-white hover:bg-brown-600" */}
                      <div onClick={() => changePage(0)}><MenuItem icon="home" title="Home" active={page == 0} /></div>
                      <div onClick={() => changePage(1)}><MenuItem icon="getStarted" title="Connect store" active={page == 1} /></div>
                      <div onClick={!store_connected ? () => changePage(1) : null}><MenuItem link={store_connected && user.myshopify_domain ? "https://coffee-bean.herokuapp.com/login?shop=" + user.myshopify_domain : "#"} icon="products" title="Open Dripshipper App" external={store_connected && user.myshopify_domain ? true : false} /></div>
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="px-2 space-y-1">
                          <div onClick={() => changePage(5)}><MenuItem link="#" icon="settings" title="Settings" active={page == 5} /></div>
                          <MenuItem link="https://dripshipper.getgist.help" icon="help" title="Help" external />
                        <div onClick={props.handleLogout}><MenuItem link="#" icon="logout" title="Log out" /></div>
                      </div>
                    </div>
                  </nav>
                </div>
              </Transition>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </div>
          {/* Static sidebar for desktop */}
          <div className="hidden lg:flex lg:flex-shrink-0">
            <div className="flex flex-col w-64">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex flex-col flex-grow bg-brown-600 pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                  <img className="w-auto" src="https://dripshipper.s3-us-west-2.amazonaws.com/logos/saasland-template-logo-white.png" alt="Easywire logo" />
                </div>
                <nav className="mt-5 flex-1 flex flex-col divide-y divide-brown-700 overflow-y-auto" aria-label="Sidebar">
                  <div className="px-2 space-y-1">
                    {/* Current: "bg-brown-600 text-white", Default: "text-brown-100 hover:text-white hover:bg-brown-600" */}
                    <div onClick={() => changePage(0)}><MenuItem icon="home" title="Home" active={page == 0} /></div>
                    <div onClick={() => changePage(1)}><MenuItem icon="getStarted" title="Connect store" active={page == 1} /></div>
                    <div onClick={!store_connected ? () => changePage(1) : null}><MenuItem link={store_connected && user.myshopify_domain ? "https://coffee-bean.herokuapp.com/login?shop=" + user.myshopify_domain : "#"} icon="products" title="Open Dripshipper App" external={store_connected && user.myshopify_domain ? true : false} /></div>
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="px-2 space-y-1">
                        <div onClick={() => changePage(5)}><MenuItem link="#" icon="settings" title="Settings" active={page == 5} /></div>
                        <MenuItem link="https://dripshipper.getgist.help" icon="help" title="Help" external />
                      <div onClick={props.handleLogout}><MenuItem link="#" icon="logout" title="Log out" /></div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-auto focus:outline-none" tabIndex={0}>
            <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
              {pages[page]}
            </main>
          </div>
        </div>
      </>
    ) : <div>Please log in.</div>
  } else {
    return <div>Loading...</div>
  }
}