import React, { useState, useEffect } from "react";
import { BrowserRouter as BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../components/Home";
import axios from "axios";
import Signup from "../components/Signup";
import Login from "../components/Login";
import ForgotPassword from "../components/ForgotPassword";
import PricingPage from "../components/PricingPage";
import ChangePassword from "../components/ChangePassword";
import SubscriptionSuccess from "../components/SubscriptionSuccess";

export default () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginChecked, setLoginChecked] = useState(false);
  const [user, setUser] = useState({});
  
  const handleLogin = (data) => {
    setIsLoggedIn(true);
    setUser(data.user);
    setLoginChecked(true);
  }
  
  const handleLogout = async () => {
    await axios.post('/api/v1/users/logout', { user }, { withCredentials: true });
    setIsLoggedIn(false);
    setUser({});
    setLoginChecked(true);
  }

  const loginStatus = () => {
    axios.get('/api/v1/users/logged_in',
      { withCredentials: true })
      .then(response => {
        if (response.data.logged_in) {
          handleLogin(response)
        } else {
          handleLogout()
        }
      })
      .catch(error => console.log('api errors:', error))
  };

  useEffect(() => {
    loginStatus();
  }, []);

  const allProps = isLoggedIn ? JSON.parse(document.getElementById('app').getAttribute('data')) : { user: { id: null }};

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route
            exact path='/'
            render={props => (
              <Home {...props} handleLogout={handleLogout} loggedInStatus={isLoggedIn} loginChecked={loginChecked} userID={allProps.user.id} storeConnected={allProps.store_connected} has_subscription={allProps.has_subscription} />)}
          />
          <Route
            exact path='/signup'
            render={props => (
              <Signup {...props} handleLogout={handleLogout} loggedInStatus={isLoggedIn} handleLogin={handleLogin} />)}
          />
          <Route
            exact path='/login'
            render={props => (
              <Login {...props} handleLogout={handleLogout} loggedInStatus={isLoggedIn} handleLogin={handleLogin} />)}
          />
          <Route
            exact path='/forgot_password'
            render={props => (
              <ForgotPassword {...props} handleLogout={handleLogout} loggedInStatus={isLoggedIn} handleLogin={handleLogin} />)}
          />

          <Route
            exact path='/pricing'
            render={props => (
              <PricingPage {...props} handleLogout={handleLogout} loggedInStatus={isLoggedIn} handleLogin={handleLogin} user={allProps.user} />)}
          />

          <Route
            exact path='/new_password/:token'
            render={props => (
              <ChangePassword {...props}  />)}
          />

          <Route
            exact path='/success/:email'
            render={props => (
              <SubscriptionSuccess {...props} />)}
          />
        </Switch>
      </BrowserRouter>
    </div>
  )
}