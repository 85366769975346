import React, { useState, useEffect } from 'react';
import Alert from './templates/Alert';
import axios from 'axios';

export default({ user }) => {
  const [state, setState] = useState({
    errors: [],
  });
  const [clicked, setClicked] = useState(false);
  const [status, setStatus] = useState('error');
  const [portalURL, setPortalURL] = useState('');
  const [email, setEmail] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);
  }

  const handleUpdate = async () => {
    setState({
      ...state,
      errors: []
    });
    setStatus("reset");
    let result = await axios.post('/api/v1/users/' + user.id, {
      email: email,
    });
    if (result.data.status == "ok") {
      setState({
        ...state,
        errors: ["Email saved."]
      });
      setStatus("reset");
      setStatus("success");
    } else {
      setState({
        ...state,
        errors: []
      });
      setState({
        ...state,
        errors: [result.data.errors]
      });
      setStatus("error");
    }
  }

  const toggleAlert = () => {
    setStatus('reset');
    setState({ ... state,
      errors: []
    });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setClicked(true);
    let email = user.email;

    axios.post('/api/v1/password/forgot', { email: email }, { withCredentials: true })
      .then(response => {
        if (response.data.status == "ok") {
          setState({
            ...state,
            errors: ["Email sent. Check your inbox for a link to reset your password."]
          });
          setStatus("reset");
          setStatus("success");
        } else {
          setState({
            ...state,
            errors: []
          });
          setState({
            ...state,
            errors: [response.data.errors]
          });
          setStatus("error");
          setClicked(false);
        }
      })
      .catch(error => console.log('api errors:', error));
  }

  useEffect(() => {
    const fetchData = async () => {
      let result = await axios.get('/api/v1/stripe/create_portal_session', {params: {
        user_id: user.id,
      }});
      setPortalURL(result.data.url);
    }
    if (user.id) fetchData();
    setEmail(user.email);
  }, [user.id]);
  let { errors } = state;

  return (
    <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <Alert messages={errors} status={status} active={errors.length > 0 || status == "success"} onClose={toggleAlert} />
      <div className="pt-10 pb-16">
        <div className="px-4 sm:px-6 md:px-0">
          <h1 className="text-3xl font-extrabold text-gray-900">Settings</h1>
        </div>
        <div className="px-4 sm:px-6 md:px-0">
          <div className="py-6">
            {/* Description list with inline editing */}
            <div className="mt-10 divide-y divide-gray-200">
              <div className="space-y-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Account
                </h3>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Email
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 items-center">
                      <span className="flex-grow">
                        <div>
                          <label htmlFor="email-address" className="sr-only">Email address</label>
                          <input id="email-address" onChange={handleChange} value={email} name="email" type="email" autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
                        </div>
                      </span>
                      <span className="ml-4 flex-shrink-0">
                        <button onClick={handleUpdate} type="button" className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                          Update
                        </button>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Plan
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow">{user.subscription_plan}</span>
                      <span className="ml-4 flex-shrink-0">
                        <a href={portalURL} className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                          Change
                        </a>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Password
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow italic">hidden</span>
                      <span className="ml-4 flex-shrink-0">
                        <button onClick={handleSubmit} type="button" disabled={clicked} className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                          Reset
                        </button>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}